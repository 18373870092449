





























































































import { ref, onMounted, defineComponent } from "@vue/composition-api";
import { useUserStore } from "@/stores/userStore";
import { OrganizationPermissionType, Roles } from "@/api/OtiumAppApi";

export default defineComponent({
  emits: ["createTemplate"],
  setup(props, { emit, refs }) {
    const displayCreateTemplateDialog = ref(false);
    const isFormValid = ref(true);
    const organizations = ref<any[]>([]);
    const selectedOrganization = ref<string | null>(null);
    const templateName = ref("");
    const templateSummary = ref("");
    const rules = [(v: string) => !!v || "Required"];
    const errorMessage = ref("");
    const creatingTemplate = ref(false);

    const getOrganizations = () => {
      const userStore = useUserStore();
      const userOrgs = userStore.userProfileOrganizations;
      if (userOrgs != null) {
        userOrgs.forEach((org) => {
          if (
            org.organizationPermissions.includes(OrganizationPermissionType.CanCreateTemplate) &&
            org.organizationCanCreateTemplates
          ) {
            organizations.value.push({ text: org.organizationName, value: org.organizationId });
          }
        });
      }
      if (userStore.userRoles != null) {
        if (
          userStore.userRoles.includes(Roles.OtiumTemplateCreator) ||
          userStore.userRoles.includes(Roles.OtiumAdmin)
        ) {
          organizations.value.unshift({
            text: "All Organizations (Global Template)",
            value: "00000000-0000-0000-0000-000000000000"
          });
        }
      }

      if (organizations.value.length == 1) {
        selectedOrganization.value = organizations.value[0].value;
      }
    };

    const cancel = () => {
      (refs.createTemplateForm as HTMLFormElement).reset();
    };

    const createTemplateClicked = () => {
      creatingTemplate.value = true;
      emit("createTemplate", selectedOrganization.value, templateName.value, templateSummary.value);
      creatingTemplate.value = false;
      displayCreateTemplateDialog.value = false;
      (refs.createTemplateForm as HTMLFormElement).reset();
    };
    onMounted(getOrganizations);

    return {
      displayCreateTemplateDialog,
      isFormValid,
      templateName,
      templateSummary,
      rules,
      errorMessage,
      creatingTemplate,
      cancel,
      createTemplateClicked,
      organizations,
      selectedOrganization
    };
  }
});
