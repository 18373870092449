






















import { defineComponent, ref } from "@vue/composition-api";
import { TemplateSummary } from "@/api/OtiumAppApi";

export default defineComponent({
  name: "DashboardTemplateSummary",
  props: {
    template: {
      type: TemplateSummary,
      required: true
    }
  },
  setup() {
    const hovered = ref(false);

    return {
      hovered
    };
  }
});
