
























































import { ref, defineComponent, onMounted, computed } from "@vue/composition-api";
import Vue from "vue";
import OtiumBasePage from "@/components/OtiumBasePage.vue";
import DashboardTemplateSummary from "@/components/Templates/DashboardTemplateSummary.vue";
import CreateTemplateDialog from "@/components/Templates/CreateTemplateDialog.vue";
import {
  TemplatesClient,
  TemplateSummary,
  TemplateTagModel,
  CreateTemplateModel
} from "@/api/OtiumAppApi";

export default defineComponent({
  components: { OtiumBasePage, DashboardTemplateSummary, CreateTemplateDialog },
  props: {},
  setup(props, { root }) {
    const templates = ref<Array<TemplateSummary>>([]);
    const client = new TemplatesClient();
    const tags = ref({} as { [key: string]: TemplateTagModel });
    const filterTag = ref(null as string | null);

    const sortedTags = computed(() => {
      const tagMap = tags.value;
      const sortedTags: TemplateTagModel[] = [];
      Object.keys(tagMap).forEach((key) => {
        sortedTags.push(tagMap[key]);
      });
      return sortedTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    });

    const loadingTemplates = ref(false);
    const fetchTemplates = async () => {
      loadingTemplates.value = true;
      templates.value = await client.getTemplates();
      templates.value.forEach((template) =>
        template.tags.forEach((tag) => {
          const tagMap = tags.value;
          if (tagMap[tag.tagId] == undefined) Vue.set(tagMap, tag.tagId, tag);
        })
      );
      loadingTemplates.value = false;
    };

    onMounted(async () => {
      await fetchTemplates();
    });

    const searchTerm = ref("");

    const filteredTemplates = computed(() =>
      templates.value.filter(
        (template) =>
          template.name.toLocaleUpperCase().includes(searchTerm.value.toLocaleUpperCase()) &&
          (filterTag.value == null || template.tags.some((tag) => tag.tagId == filterTag.value))
      )
    );

    const createTemplate = async (tplOrg: string, tplName: string, tplSummary: string) => {
      const model = {
        name: tplName,
        summary: tplSummary,
        organizationId: tplOrg
      } as CreateTemplateModel;

      await client.createTemplate(model);
      await fetchTemplates();
    };

    const goToArchived = () => {
      root.$router.push({ name: "ArchivedTemplates" });
    };

    return {
      templates,
      sortedTags,
      filterTag,
      createTemplate,
      searchTerm,
      filteredTemplates,
      goToArchived,
      loadingTemplates
    };
  }
});
